import { Layout } from 'antd';
import React, { FC } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';

import { LocationPage } from './pages/Location/LocationPage';

import './App.scss';

const App: FC = () => (
    <Layout className="layout" style={{ minHeight: '100vh' }}>
        <Layout.Header>
            <div className="logo">
                <a>
                    <img src="/static/map.png" />
                    <h1>Our Locations</h1>
                </a>
            </div>
        </Layout.Header>
        <Layout.Content style={{ padding: '25px' }}>
            <div className="site-layout-content">
                <Switch>
                    <Route exact path="/">
                        <LocationPage />
                    </Route>
                </Switch>
            </div>
        </Layout.Content>
        <Layout.Footer style={{ textAlign: 'center' }}>&nbsp;</Layout.Footer>
    </Layout>
);

export default withRouter(App);
