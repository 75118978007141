import { useReducer } from 'react';

import { initialLocationsState, LocationContextDispatch, LocationContextState } from 'src/store/context';
import { locationsReducer } from 'src/store/reducers';

type LocationsProviderProps = {
    children?: React.ReactNode;
};

export const LocationsProvider = ({ children }: LocationsProviderProps): JSX.Element => {
    const [state, dispatch] = useReducer(locationsReducer, initialLocationsState);

    return (
        <LocationContextState.Provider value={state}>
            <LocationContextDispatch.Provider value={dispatch}>{children}</LocationContextDispatch.Provider>
        </LocationContextState.Provider>
    );
};
