import { Button, Radio, RadioChangeEvent } from 'antd';
import { Feature, GeoJsonObject } from 'geojson';
import L, { Layer, PathOptions } from 'leaflet';
import { useState } from 'react';
import { useMap } from 'react-leaflet';

import { DisplayType, Location } from 'src/models';

import './MapData.scss';

interface MapDataProps {
    locations: Location[];
}

export const MapData = ({ locations }: MapDataProps): JSX.Element => {
    const map = useMap();

    const [displayType, setDisplayType] = useState<DisplayType>(DisplayType.Line);

    const coordinates = locations.map((location: Location) => [location.longitude, location.latitude]);
    const lastCoordinate = coordinates[coordinates.length - 1];

    const geoJsonFeatures = {
        type: 'FeatureCollection',
        features: [
            {
                type: 'Feature',
                properties: {
                    isVisible: true,
                    displayType: DisplayType.Line
                },
                geometry: {
                    type: 'LineString',
                    coordinates: coordinates
                }
            },
            {
                type: 'Feature',
                properties: {
                    isVisible: true,
                    displayType: DisplayType.MultiPoint
                },
                geometry: {
                    type: 'MultiPoint',
                    coordinates: coordinates
                }
            },
            {
                type: 'Feature',
                properties: {
                    popupContent: 'Last tracked location',
                    isVisible: true
                },
                geometry: {
                    type: 'Point',
                    coordinates: lastCoordinate
                }
            }
        ]
    } as GeoJsonObject;

    const geoJson = new L.GeoJSON(geoJsonFeatures, {
        onEachFeature: (feature: Feature, layer: Layer): void => {
            if (!feature.properties) {
                return;
            }

            if (feature.properties.popupContent) {
                const popupContent = feature.properties.popupContent;
                layer.bindPopup(popupContent);
                layer.on({
                    mouseover: () => {
                        layer.openPopup();
                    },
                    mouseout: () => {
                        layer.closePopup();
                    }
                });
            }
        },
        filter: (feature: Feature): boolean => {
            if (!feature.properties || !feature.properties.displayType) {
                return true;
            }
            return displayType === feature.properties.displayType;
        },
        style: (): PathOptions => ({
            color: '#3388ff',
            weight: 3,
            opacity: 0.7
        })
    });

    map.eachLayer((layer: L.Layer) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if (!!(layer as any).toGeoJSON) {
            map.removeLayer(layer);
        }
    });

    geoJson.addTo(map);
    map.fitBounds(geoJson.getBounds());

    const handleCenterOnLastPosition = (): void => {
        map.fitBounds([[lastCoordinate[1], lastCoordinate[0]]]);
    };

    const handleCenterOnRoute = (): void => {
        map.fitBounds(geoJson.getBounds());
    };

    const handleDisplayType = (e: RadioChangeEvent): void => {
        const value = e.target.value as DisplayType;
        setDisplayType(value);
    };

    return (
        <>
            <div className="leaflet-top leaflet-right">
                <div className="leaflet-control leaflet-bar">
                    <div className="map-data_controls-container">
                        <h4>View</h4>
                        <Button type="primary" size="small" onClick={handleCenterOnLastPosition}>
                            Current
                        </Button>
                        <Button type="primary" size="small" onClick={handleCenterOnRoute}>
                            Route
                        </Button>
                    </div>
                </div>
            </div>

            <div className="leaflet-bottom leaflet-right">
                <div className="leaflet-control leaflet-bar">
                    <div className="map-data_controls-container">
                        <Radio.Group defaultValue={DisplayType.Line} buttonStyle="solid" onChange={handleDisplayType}>
                            <Radio value={DisplayType.Line}>Line</Radio>
                            <Radio value={DisplayType.MultiPoint}>Points</Radio>
                        </Radio.Group>
                    </div>
                </div>
            </div>
        </>
    );
};
