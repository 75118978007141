import { Spin } from 'antd';
import L from 'leaflet';
import iconRetina from 'leaflet/dist/images/marker-icon-2x.png';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import React, { useEffect } from 'react';
import { MapContainer, TileLayer } from 'react-leaflet';

import { State } from 'src/models';
import { useLocationsState } from 'src/store/context';
import { formatDate } from 'src/utils';

import { MapData } from './MapData';

import 'leaflet/dist/leaflet.css';
import './LocationsMap.scss';

export const LocationsMap = (): JSX.Element => {
    const state = useLocationsState();

    useEffect(() => {
        const DefaultIcon = L.icon({
            ...L.Icon.Default.prototype.options,
            iconUrl: icon,
            iconRetinaUrl: iconRetina,
            shadowUrl: iconShadow
        });
        L.Marker.prototype.options.icon = DefaultIcon;
    }, []);

    if (state.status === State.LOADING) {
        return <Spin />;
    } else if (state.status === State.ERROR) {
        return <div>Unable to load item data</div>;
    }

    if (!state.dateTo || !state.dateFrom) {
        return <h1>Date was not selected</h1>;
    }

    if (state.locations.length === 0) {
        return <h1>No recorded tracking activity for {formatDate(state.dateFrom)} :(</h1>;
    }

    return (
        <>
            <div className="locations-map_map-container">
                <h2>Tracking info on {formatDate(state.dateFrom)}</h2>

                <div className="locations-map_map-frame">
                    {state.locations && (
                        <MapContainer
                            zoom={0}
                            center={[0, 0]}
                            boundsOptions={{ padding: [20, 20] }}
                            scrollWheelZoom={false}
                        >
                            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                            <MapData locations={state.locations} />
                        </MapContainer>
                    )}
                </div>
            </div>
        </>
    );
};
