import { AppError, TrackingDate } from 'src/models';

export enum TrackingDatesActionType {
    FETCH_DATES_INIT = 'FETCH_DATES_INIT',
    FETCH_DATES_SUCCEED = 'FETCH_DATES_SUCCEED',
    FETCH_DATES_FAILED = 'FETCH_DATES_FAILED'
}

export type TrackingDatesAction = {
    type: TrackingDatesActionType;
    trackingDates?: TrackingDate[];
    error?: AppError;
};

export const fetchTrackingDatesInit = (): TrackingDatesAction => ({
    type: TrackingDatesActionType.FETCH_DATES_INIT
});

export const fetchTrackingDatesSucceed = (payload: TrackingDate[]): TrackingDatesAction => ({
    type: TrackingDatesActionType.FETCH_DATES_SUCCEED,
    trackingDates: payload
});

export const fetchTrackingDatesFailed = (error: AppError): TrackingDatesAction => ({
    type: TrackingDatesActionType.FETCH_DATES_FAILED,
    error: error
});
