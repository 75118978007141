import { createContext, Dispatch, useContext } from 'react';

import { AppError, State, TrackingDate } from 'src/models';
import { TrackingDatesAction } from 'src/store/actions';

export interface TrackingDatesState {
    trackingDates: TrackingDate[];
    status: State;
    error?: AppError;
}

export const initialTrackingDatesState: TrackingDatesState = {
    trackingDates: [],
    status: State.IDLE
};

export type TrackingDatesContext = {
    state: TrackingDatesState;
    dispatch: Dispatch<TrackingDatesAction>;
};

export const TrackingDatesContextState = createContext<TrackingDatesState>(initialTrackingDatesState);
export const TrackingDatesContextDispatch = createContext<Dispatch<TrackingDatesAction>>(() => null);

export const useTrackingDatesState = (): TrackingDatesState => {
    const context = useContext(TrackingDatesContextState);
    if (!context) {
        throw new Error('useTrackingDatesState must be used within the TrackingDatesContextState.Provider tag');
    }
    return context;
};

export const useTrackingDatesDispatch = (): Dispatch<TrackingDatesAction> => {
    const context = useContext(TrackingDatesContextDispatch);
    if (!context) {
        throw new Error('useTrackingDatesDispatch must be used within the TrackingDatesContextDispatch.Provider tag');
    }
    return context;
};
