import { CalendarOutlined } from '@ant-design/icons';
import { Button, Calendar } from 'antd';
import React, { useEffect, useState } from 'react';

import { State, TrackingDate } from 'src/models';
import { useFetchLocations, useFetchTrackingDates } from 'src/services';
import { useLocationsState, useTrackingDatesState } from 'src/store/context';
import { formatDate } from 'src/utils';

import './LocationsFilter.scss';

export const LocationsFilter = (): JSX.Element => {
    const locationsState = useLocationsState();
    const datesState = useTrackingDatesState();
    const { fetchLocations } = useFetchLocations();
    const { fetchTrackingDates } = useFetchTrackingDates();

    const [showCalendar, setShowCalendar] = useState<boolean>(false);
    const lastTrackingDaysNum = 6;

    useEffect(() => {
        fetchLocations();
        fetchTrackingDates();
    }, [fetchLocations, fetchTrackingDates]);

    const handleSearch = (trackingDate: TrackingDate): void => {
        const date = new Date(trackingDate.date);
        fetchLocations(date);
    };

    const sortedDates = datesState.trackingDates.sort(
        (a: TrackingDate, b: TrackingDate): number => new Date(b.date).getTime() - new Date(a.date).getTime()
    );

    const onCalendarSelect = (value: moment.Moment): void => {
        fetchLocations(value.toDate());
        setShowCalendar(false);
    };

    const disabledDate = (calendarDate: moment.Moment): boolean => {
        if (calendarDate.isAfter()) {
            return true;
        }
        const hasDate = sortedDates
            .map((trackingDate: TrackingDate) => new Date(trackingDate.date))
            .filter(
                (date: Date) =>
                    calendarDate.date() === date.getDate() &&
                    calendarDate.month() === date.getMonth() &&
                    calendarDate.year() === date.getFullYear()
            );
        return hasDate.length === 0 ? true : false;
    };

    const toggleCalendarVisibility = (): void => {
        setShowCalendar(!showCalendar);
    };

    return (
        <>
            <div>
                <h2>Recent tracking dates</h2>

                {sortedDates.slice(0, lastTrackingDaysNum).map((trackingDate: TrackingDate, index: number) => (
                    <Button
                        key={index}
                        className="locations-filter_date-btn"
                        type="primary"
                        onClick={(): void => handleSearch(trackingDate)}
                        disabled={locationsState.status === State.LOADING}
                    >
                        {formatDate(trackingDate.date)}
                    </Button>
                ))}

                <Button
                    className="locations-filter_date-btn"
                    icon={<CalendarOutlined />}
                    onClick={toggleCalendarVisibility}
                >
                    {showCalendar ? <span>Hide more</span> : <span>Show more</span>}
                </Button>
            </div>

            {showCalendar ? (
                <div className="locations-filter_calendar-container">
                    <Calendar fullscreen={false} onSelect={onCalendarSelect} disabledDate={disabledDate} />
                </div>
            ) : null}
        </>
    );
};
