import { State } from 'src/models';
import { TrackingDatesAction, TrackingDatesActionType } from 'src/store/actions';
import { TrackingDatesState } from 'src/store/context';

export const trackingDatesReducer = (state: TrackingDatesState, action: TrackingDatesAction): TrackingDatesState => {
    switch (action.type) {
        case TrackingDatesActionType.FETCH_DATES_INIT:
            return {
                trackingDates: [],
                status: State.LOADING
            };
        case TrackingDatesActionType.FETCH_DATES_SUCCEED:
            return {
                ...state,
                trackingDates: action.trackingDates || [],
                status: State.LOADED
            };
        case TrackingDatesActionType.FETCH_DATES_FAILED:
            return {
                ...state,
                trackingDates: [],
                status: State.ERROR,
                error: state.error
            };
        default:
            return state;
    }
};
