import { AppError, Location } from 'src/models';

export enum LocationsActionType {
    FETCH_LOCATIONS_INIT = 'FETCH_LOCATIONS_INIT',
    FETCH_LOCATIONS_SUCCEED = 'FETCH_LOCATIONS_SUCCEED',
    FETCH_LOCATIONS_FAILED = 'FETCH_LOCATIONS_FAILED'
}

export type LocationsAction = {
    type: LocationsActionType;
    dateFrom?: Date;
    dateTo?: Date;
    locations?: Location[];
    error?: AppError;
};

export const fetchLocationsInit = (dateFrom: Date, dateTo: Date): LocationsAction => ({
    type: LocationsActionType.FETCH_LOCATIONS_INIT,
    dateFrom: dateFrom,
    dateTo: dateTo
});

export const fetchLocationsSucceed = (payload: Location[]): LocationsAction => ({
    type: LocationsActionType.FETCH_LOCATIONS_SUCCEED,
    locations: payload
});

export const fetchLocationsFailed = (error: AppError): LocationsAction => ({
    type: LocationsActionType.FETCH_LOCATIONS_FAILED,
    error: error
});
