import { useCallback, useMemo } from 'react';

import { LocationsApiUrl } from 'src/config/settings';
import { AppError, Location } from 'src/models';
import { fetchLocationsFailed, fetchLocationsInit, fetchLocationsSucceed } from 'src/store/actions';
import { useLocationsDispatch } from 'src/store/context';

export const useFetchLocations = (): {
    fetchLocations: (dateFrom?: Date | undefined, dateTo?: Date | undefined) => void;
} => {
    const dispatch = useLocationsDispatch();

    const fetchLocations = useCallback(
        (date?: Date) => {
            if (!date) {
                date = new Date();
            }
            const utcFrom = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);
            const dateFrom = new Date(utcFrom);
            const utcTo = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59, 999);
            const dateTo = new Date(utcTo);

            dispatch(fetchLocationsInit(dateFrom, dateTo));
            fetch(`${LocationsApiUrl}/locations?dateFrom=${dateFrom.toISOString()}&dateTo=${dateTo.toISOString()}`)
                .then((response: Response) => response.json())
                .then((fetchedLocations: Location[]) => {
                    dispatch(fetchLocationsSucceed(fetchedLocations));
                })
                .catch((error: Error) => {
                    dispatch(fetchLocationsFailed(new AppError('Failed to fetch locations', error)));
                });
        },
        [dispatch]
    );

    return useMemo(
        () => ({
            fetchLocations
        }),
        [fetchLocations]
    );
};
