import { FC, useReducer } from 'react';

import { initialTrackingDatesState, TrackingDatesContextDispatch, TrackingDatesContextState } from 'src/store/context';
import { trackingDatesReducer } from 'src/store/reducers';

type FilterProviderProps = {
    children?: React.ReactNode;
};

export const FilterProvider: FC = ({ children }: FilterProviderProps) => {
    const [state, dispatch] = useReducer(trackingDatesReducer, initialTrackingDatesState);

    return (
        <TrackingDatesContextState.Provider value={state}>
            <TrackingDatesContextDispatch.Provider value={dispatch}>{children}</TrackingDatesContextDispatch.Provider>
        </TrackingDatesContextState.Provider>
    );
};
