import { useCallback, useMemo } from 'react';

import { LocationsApiUrl } from 'src/config/settings';
import { AppError, TrackingDate } from 'src/models';
import { fetchTrackingDatesFailed, fetchTrackingDatesInit, fetchTrackingDatesSucceed } from 'src/store/actions';
import { useTrackingDatesDispatch } from 'src/store/context';

export const useFetchTrackingDates = (): {
    fetchTrackingDates: () => void;
} => {
    const dispatch = useTrackingDatesDispatch();

    const fetchTrackingDates = useCallback(() => {
        dispatch(fetchTrackingDatesInit());
        fetch(`${LocationsApiUrl}/dates`)
            .then((response: Response) => response.json())
            .then((fetchedTrackingDates: TrackingDate[]) => {
                dispatch(fetchTrackingDatesSucceed(fetchedTrackingDates));
            })
            .catch((error: Error) => {
                dispatch(fetchTrackingDatesFailed(new AppError('Failed to fetch locations dates', error)));
            });
    }, [dispatch]);

    return useMemo(
        () => ({
            fetchTrackingDates
        }),
        [fetchTrackingDates]
    );
};
