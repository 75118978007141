import { State } from 'src/models';
import { LocationsAction, LocationsActionType } from 'src/store/actions';
import { LocationsState } from 'src/store/context';

export const locationsReducer = (state: LocationsState, action: LocationsAction): LocationsState => {
    switch (action.type) {
        case LocationsActionType.FETCH_LOCATIONS_INIT:
            return {
                dateFrom: action.dateFrom,
                dateTo: action.dateTo,
                locations: [],
                status: State.LOADING
            };
        case LocationsActionType.FETCH_LOCATIONS_SUCCEED:
            return {
                ...state,
                locations: action.locations || [],
                status: State.LOADED
            };
        case LocationsActionType.FETCH_LOCATIONS_FAILED:
            return {
                ...state,
                locations: [],
                status: State.ERROR,
                error: state.error
            };
        default:
            return state;
    }
};
