import { createContext, Dispatch, useContext } from 'react';

import { AppError, Location, State } from 'src/models';
import { LocationsAction } from 'src/store/actions';

export interface LocationsState {
    dateFrom?: Date;
    dateTo?: Date;
    locations: Location[];
    status: State;
    error?: AppError;
}

export const initialLocationsState: LocationsState = {
    locations: [],
    status: State.IDLE
};

export type LocationsContext = {
    state: LocationsState;
    dispatch: Dispatch<LocationsAction>;
};

export const LocationContextState = createContext<LocationsState>(initialLocationsState);
export const LocationContextDispatch = createContext<Dispatch<LocationsAction>>(() => null);

export const useLocationsState = (): LocationsState => {
    const context = useContext(LocationContextState);
    if (!context) {
        throw new Error('useLocationsState must be used within the LocationContextState.Provider tag');
    }
    return context;
};

export const useLocationsDispatch = (): Dispatch<LocationsAction> => {
    const context = useContext(LocationContextDispatch);
    if (!context) {
        throw new Error('useLocationsDispatch must be used within the LocationContextDispatch.Provider tag');
    }
    return context;
};
