import { FilterProvider, LocationsProvider } from 'src/store/providers';

import { LocationsFilter } from '../components/LocationsFilter';
import { LocationsMap } from '../components/LocationsMap';

import './LocationPage.scss';

export const LocationPage = (): JSX.Element => (
    <>
        <LocationsProvider>
            <FilterProvider>
                <LocationsMap />
                <div className="location-page_filter-container">
                    <LocationsFilter />
                </div>
            </FilterProvider>
        </LocationsProvider>
    </>
);
